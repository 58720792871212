export default {
  name: 'CardWidgetMixin',
  props: {
    enableCard: {
      type: Boolean,
      default: true,
    },
    enableCardSize: {
      type: String,
      default: null,
      validator(size) {
        if (!size) return true;

        return ['xs', 'sm', 'md', 'lg', 'xl'].includes(size);
      },
    },
  },
};
