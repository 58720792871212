export function checkPermissions(
  neededAction,
  neededModel,
  roles,
  community = { isStaff: false },
  neededSubModel
) {
  const userModelsAndActions = [];
  if (community.isStaff === true) {
    return true;
  }

  if (
    community.accessibility === 3 &&
    (neededAction === "view" || neededAction === "index")
  ) {
    return true;
  }

  if (roles == null || roles.length === 0) {
    return false;
  }
  for (let row of roles) {
    for (let permission of row.permissions) {
      userModelsAndActions.push({
        modelType: permission.modelType ?? "",
        action: permission.action ?? "",
        submodelType: permission.submodelType ?? "",
      });
    }
  }

  if (neededSubModel != null) {
    for (let row of userModelsAndActions) {
      if (row.submodelType === neededSubModel && row.action === neededAction && row.modelType === neededModel) {
        return true;
      }
    }
  }
  if (neededSubModel == null) {
    for (let row of userModelsAndActions) {
      if (
        row.modelType === neededModel &&
        row.action === neededAction &&
        (row.submodelType === "" || row.submodelType == null)
      ) {
        return true;
      }
    }
  }

  return false;
}


export function checkPermissionsForStaffs(
  neededAction,
  neededModel,
  roles,
  community = {},
  neededSubModel,
  mainCommunity = {}
) {
  // if (mainCommunity.isStaff) {
  //   return true;
  // }

  const userModelsAndActions = [];
  if (community.isStaff === false) {
    return false;
  }

  if (
    community.accessibility === 3 &&
    (neededAction === "view" || neededAction === "index")
  ) {
    return true;
  }

  if (roles == null || roles.length === 0) {
    return false;
  }
  for (let row of roles) {
    for (let permission of row.permissions) {
      userModelsAndActions.push({
        modelType: permission.modelType ?? "",
        action: permission.action ?? "",
        submodelType: permission.submodelType ?? "",
      });
    }
  }

  if (neededSubModel != null) {
    for (let row of userModelsAndActions) {
      if (row.submodelType === neededSubModel && row.action === neededAction && row.modelType === neededModel) {
        return true;
      }
    }
  }
  if (neededSubModel == null) {
    for (let row of userModelsAndActions) {
      if (
        row.modelType === neededModel &&
        row.action === neededAction &&
        (row.submodelType === "" || row.submodelType == null)
      ) {
        return true;
      }
    }
  }

  return false;
}
