import { translateTranslationTable } from '@core/libs/i18n/utils';

export default {
  name: 'WidgetLayoutMixin',
  props: {
    app: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      addOnSize: 0,
    };
  },
  computed: {
    widgetName() {
      const locale = this.$store.state.locale.currentLocale;
      if (!this.app) {
        return '';
      }

      if (this.app.customization?.name) {
        return translateTranslationTable(locale, this.app.customization.name);
      }

      return translateTranslationTable(locale, this.app.name);
    },
  },
  methods: {
    getLocation(name) {
      return {
        name,
        params: {
          communityId: this.$route.params.communityId,
        },
      };
    },
    setAddOnSize(size) {
      this.addOnSize = size;
    },
    getSize(getter) {
      return (this.$store.getters[getter] && this.$store.getters[getter].meta
        && this.$store.getters[getter].meta.total) || 0;
    },
  },
};
