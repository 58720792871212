import { getCurrentInstance } from '@vue/composition-api';

/**
 * Returns translated string if i18n package is available to Vue
 * If i18n is not configured then it will simply return what is being passed
 * Useful if you don't know if i18n is configured or not
 * Used in @core files to handle absence of i18n without errors
 * @param {String} key i18n key to use for translation
 */
export const t = (key, config) => {
  const vm = getCurrentInstance().proxy;
  return vm.$t ? vm.$t(key, config) : key;
};

/**
 * Translates the translationTable to the current locale.
 *
 * @param {String} locale The locale
 * @param {TranslationTable} value Value to translate
 */
export function translateTranslationTable(locale, value) {
  if (!value) return '';

  if (typeof value === 'object') {
    //If the locale has a value:
    if(value[locale] && value[locale] !== ""){
      return value[locale];
    }
    //If not, find the first content available
    for(let [key, val] of Object.entries(value)){
      if(val && val !== ""){
        return val;
      }
    }
    //If not give the first
    return Object.values(value)[0];
  }

  return value;
}

export const _ = null;
