<template>
  <b-modal
    :title="$t('link.description-addon.title')"
    :visible="visible"
    hide-footer
    @change="$emit('change', false)"
  >
    <template v-if="widget && translateTranslationTable($store.state.locale.currentLocale, widget.tagline)">
      <h4>{{ translateTranslationTable($store.state.locale.currentLocale, title) }}</h4>
      <hr>
      <h5 v-html="translateTranslationTable($store.state.locale.currentLocale, widget.tagline)" />
    </template>
    <template v-else>
      {{ $t('available-information.message', { itemName: translateTranslationTable($store.state.locale.currentLocale, title) }) }}
    </template>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';

export default {
  name: 'DescriptionAddon',
  components: { BModal },
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    appId: {
      type: Number,
      default: 0,
    },
    visible: Boolean,
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      widget: {},
    };
  },
  computed: {
    // widget() {
    //   const app = Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]).filter((item) => {
    //     if (item.id === this.appId) {
    //       return item.name;
    //     }
    //   });
    //   return app[0];
    // },
  },
  created() {
    this.getWidgetDescription();
  },
  methods: {
    translateTranslationTable,
    getWidgetDescription() {
      const app = Object.values(
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
      ).filter((item) => {
        if (item.id === this.appId) {
          return item.name;
        }
      });
      // if (app[0]?.tagline) {
      //   this.appDescription = app[0].tagline;
      // }
      this.widget = app[0];
    },
  },
};
</script>
